// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7GMnVvZy0uibHMT9ckm-wZNMGMF7SHyY",
  authDomain: "sawicki-phu.firebaseapp.com",
  projectId: "sawicki-phu",
  storageBucket: "sawicki-phu.appspot.com",
  messagingSenderId: "568227340834",
  appId: "1:568227340834:web:a19f69f11cd2831052207b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);